
import * as _ from "lodash";
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import AddQuestionModal from '@/components/StudyCreation/AddQuestionModal.vue'
import QuestionSurvey from '~/components/SurveyCreation/QuestionSurvey.vue'
import TestPreview from '@/components/TestPreview.vue'
import NavigationRules from '~/components/SurveyCreation/NavigationRules.vue'
import ModalFullScreen from '~/components/ModalFullScreen/ModalFullScreen.vue';
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import Button from '@/components/Button.vue'
import StudyIcon from '@/components/StudyIcon.vue'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import store from '@/store'
import { formatNavigationRules,
        navigationRulesHasErrors,
        addRule,
        generateNavigationRules,
        createQuestion,
        duplicateQuestion,
        addOptionMatrix,
        addValue,
        removeRule,
        reorderQuestions,
        canRemoveQuestion,
        removeQuestionValueOption,
      removeQuestionValue} from '~/utils/survey-creation.ts'
export default defineComponent({
  name : 'Step3Survey',
  components:{
    StudyCreationBase,
    SuiteInput,
    AddQuestionModal,
    QuestionSurvey,
    draggable,
    NavigationRules,
    ModalFullScreen,
    Button,
    TestPreview,
    StudyIcon,
    ProgressModal
  },
  computed: {
    ...mapGetters([
      'surveySteps'
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  props:{
    STEP:[String, Number]
  },
  data(){
    return{
      testName: '',
      questions:new Array(),
      navigationRules:new Array(),
      isModalOpen: false,
      errorMessage:this.$t('suite_survey_step3_error'),
      error: false,
      showGeneralRules: false,
      showTestPreview: false,
      executionPreviewTestId:'',
      dataIsUpdated: false,
      showProgress: false,
      showProgressSave: false,
      saveText: this.$t('suite_survey_step3_save')
    }
  },
  methods:{
    fetchTest(){
      store.dispatch('getTest',{testId:this.$route.params.testId}).then((data) => {
        this.testName = data.test.title
        this.questions = data.testTasks[0].questions != undefined ? data.testTasks[0].questions : []
        this.executionPreviewTestId = data.test.publicToken
        if(this.questions.length > 0){
          for(let q of this.questions){
            if(q.type == 'IMAGE_CHOICE' || q.type == 'MULTIPLEIMAGE_CHOICE'){
              q.imageValues = []
              q.imageValues = q.values
              delete q.values
            }
            if (q.placeholders != undefined && q.placeholders != null) {
               q.placeholders = q.placeholders.join('//');
             }
             if(q.textDescriptions != undefined && q.textDescriptions != null) {
               q.textDescriptions = q.textDescriptions.join('//')
             }
          }
        }
      })
    },
    fetchNavigationRules(){
      store.dispatch('fetchTestNavigationRules',{testId:this.$route.params.testId}).then((data) => {
        generateNavigationRules(this.navigationRules, data)
      })
    },
    openQuestionModal(){
      this.isModalOpen = !this.isModalOpen
    },
    createQuestion(type: any){
      createQuestion(this.questions, type)
      this.dataIsUpdated = true
    },
    duplicateQuestion(question: any){
      duplicateQuestion(question, this.questions)
      this.dataIsUpdated = true
    },
    canRemoveQuestion(questionIndex: any){
      canRemoveQuestion(questionIndex, this.questions, this.navigationRules, this.$t)
      this.dataIsUpdated = true
    },
    removeQuestionValue(event: any, index: any){
      removeQuestionValue(this.questions, index, event)
      this.dataIsUpdated = true
    },
    removeQuestionValueOption(event: any, index: any){
      removeQuestionValueOption(this.questions, index, event)
      this.dataIsUpdated = true
    },
    reorderQuestions(){
      reorderQuestions(this.questions)
      this.dataIsUpdated = true
    },
    addValue(question: any, addTxt: Boolean){
      addValue(question, addTxt)
      this.dataIsUpdated = true
    },
    addOptionMatrix(question: any){
      addOptionMatrix(question)
      this.dataIsUpdated = true
    },
    beforeSave(onlySave: Boolean){
      this.error = false
      const canSaveSurvey: any = this.canSaveSurvey()
      if(canSaveSurvey.error){
        this.error = true
        this.errorMessage = canSaveSurvey.errorMessage
        return
      }
      this.saveTest(onlySave)
    },
    canSaveSurvey(){
      const title:any = this.$refs.Title
      let errors = []
      const navigationRulesError = navigationRulesHasErrors(this.navigationRules)
      errors.push(title.hasErrors())
      errors.push(this.questions.length == 0)
      errors.push(navigationRulesError)

      for(let index in this.questions){
        const question: any = this.$refs['Question-' + index]
        errors.push(question.hasErrors())
      }
      const errorMessage = navigationRulesError ? this.$t('suite_survey_step3_error_logic') : this.$t('suite_survey_step3_error')
      return {
        error: errors.includes(true),
        errorMessage: errorMessage
      }
    },
    addRule(question: any){
      addRule(this.navigationRules, question)
      this.dataIsUpdated = true
    },
    removeRule(ruleIndex: any){
      removeRule(this.navigationRules, ruleIndex)
    },
    changeRule(){
      this.dataIsUpdated = true
    },
    saveTest(onlySave: Boolean){
      this.error = false
      let questions = JSON.parse(JSON.stringify(this.questions))
      for(let q of questions){
        if(q.placeholders != undefined && typeof q.placeholders == "string") q.placeholders = q.placeholders.split('//')
        if(q.textDescriptions != undefined && typeof q.textDescriptions == "string") q.textDescriptions = q.textDescriptions.split('//')
      }
      const data = {
        testName: this.testName,
        questions: questions,
        navigationRules: {
          conditions:formatNavigationRules(this.navigationRules)
        }
      }
      if (!onlySave) {
        this.showProgress = true
      } else {
        this.showProgressSave = true
      }
      store.dispatch('saveWATSurvey', {contractId:this.$route.params.id, data:data})
      .then((data) => {
        this.showProgress = false
        this.showProgressSave = false
        if(onlySave) {
          Swal.fire({
            title:this.$t('msg_saved_data'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
          this.dataIsUpdated = false
          return
        }
        else this.$router.push({name:'newstudy.step4Survey', params:{id: this.$route.params.id, testId:this.$route.params.testId}})
      })
      .catch((response)  => {
        this.error = true
        this.errorMessage = this.$t('suite_study_error_save')
        this.showProgress = false
        this.showProgressSave = false
      })
    },
    openPreview(){
      if(this.dataIsUpdated){
        Swal.fire({
          text:this.$t('msg_new_activity_open_preview'),
          icon:"warning",
          customClass:{popup:"swal-customWarning"}
        })
        return
      }
      this.showTestPreview = true
    }
  },
  mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
    this.fetchTest()
    this.fetchNavigationRules()
  }
})
